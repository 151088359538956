import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Container, Logo, TextContainer, AfterFooter } from "./styles";
import PhoneIcon from "../../assets/icons/phone.svg";
import MailIcon from "../../assets/icons/mail.svg";
import PinIcon from "../../assets/icons/v2/pin.svg";
import LogoSvg from "../../assets/images/logo.svg";

const Footer = () => {
  return (
    <>
      <Container>
        <Logo>
          <LogoSvg />
        </Logo>

        <TextContainer>
          <h2>Contact Us</h2>
          <p>
            <PhoneIcon />
            <a href="tel:+1 (972)351-1475">+1 972 351-1475</a>
          </p>
          <p>
            <MailIcon />
            <a
              href="mailto:fiftystatestackle@yahoo.com"
              target="_blank"
              rel="noreferrer"
            >
              fiftystatestackle@yahoo.com
            </a>
          </p>
          <p>
            <PinIcon />
            <a
              href="https://www.google.com/maps/place/247+W+Bonita+Ave,+San+Dimas,+CA+91773,+USA/@34.1068907,-117.8134878,17z/data=!3m1!4b1!4m5!3m4!1s0x80c32f3c37f6dadf:0x802c11416823be12!8m2!3d34.1068907!4d-117.8112991"
              target="_blank"
              rel="noreferrer"
            >
              247 W. Bonita Ave <br />
              San Dimas, CA 91773
            </a>
          </p>
        </TextContainer>
      </Container>
      <Container>
        <AfterFooter>
          <p>
            Copyright, patent and trademark by Chi Huynh | Patent No. US
            7,854,086 | US Trademark No.: 97/349,492
          </p>
          <StaticImage
            src="../../assets/images/v2/cards.png"
            alt="Accepted credit cards"
            quality="100"
            style={{ height: "100%", width: "auto" }}
          />
        </AfterFooter>
      </Container>
    </>
  );
};

export default Footer;
