import React from "react";

import Accordion, { AccordionItem } from "../../components/Accordion";

import { Container, Title } from "./styles";

const FAQ = () => {
  const faqs = [
    {
      question: "Can I use a conventional reel with this rod?",
      answer:
        "<p>No, at the moment, the DavinChi Rod is explicitly designed for spinning reels. The fish caught in our videos were primarily caught with the Tsunami SaltX 4000, 5000, and 6000 and the Van Staal VS X series.</p>",
    },
    {
      question: "What is the best spinning reel for this rod?",
      answer:
        "<p>There are many good brands out there you can select from to fit your budget. We personally prefer and use the Tsunami SaltX  5000 or 6000 and the Van Staal VS X series.</p>",
    },
    {
      question: "What size of fish can I catch with this fishing rod?",
      answer:
        "<p>While the Davinchi rod is officially rated for 8-12 lbs, the rod has an extraordinary tip. Not only be light and sensitive enough to feel and catch a 1lb fish, but it also has enough backbone to catch a 25lbs plus fish, as can be seen in our videos. This means you only need a DavinChi rod to do most of your everyday type of fishing. While the current tip is not designed for giant grouper or 100 lbs plus tuna, we’ve seen fantastic fisherman pull in some huge fish well beyond its rating. Though we intend to offer higher-weight rods in the future, you can reach out to us and special order an additional heavier tip for bigger fish.</p>",
    },
    {
      question: "Can I order a bigger or smaller size tip?",
      answer:
        "<p>DavinChi rods are specially designed for interchangeable tips to save money from buying multiple fishing rods. Our current tip on this website is suitable for catching fish size 2lbs to 25lbs for everyday usage. We are producing bigger tip sizes: 15-30 lbs and 35-50 lbs, and in the next few months you will be able to order these tips directly from the site. In the meantime, let us know what other size of tip you may need, and we will create a custom-made tip to accommodate your request.</p>",
    },
    {
      question: "Can I use one arm to catch a bigger fish with this rod?      ",
      answer:
        "<p>Yes, moving the leverage from the wrist to the elbow frees up more strength in your single-arm, allowing for a greater force in catching fish one-handed. When encountering even bigger fish where both arms are needed, you can maximize your strength by allowing both arms to pull with more efficiency. Essentially, you will be able to catch bigger fish in general.</p>",
    },
    {
      question: "Will it sit on a boat?",
      answer: "<p>Yes, it's designed to fit most boat inserts.</p>",
    },
    {
      question: "How do I cast it?",
      answer:
        "<p>You cast it the same way you would a traditional rod with a spinning reel.</p>",
    },
    {
      question: "Can I use this rod for bass or trout?",
      answer:
        "<p>Yes, though this rod is specifically designed and weighted for bigger fish. We also plan to create smaller weighted rods in the near future.</p>",
    },
    {
      question: "What is the DavinChi Rod Community? ",
      answer:
        "<p>The Community is a group of fishing enthusiasts that share photos and videos of their favorite catches using the DavinChi rod to be featured on social media and YouTube. and the DavinChi Rod YouTube Channel. Community members also receive exclusive pricing and discounts on DavinChi Rod products.</p>",
    },
  ];

  return (
    <Container>
      <div>
        <Title>Frequently Asked questions</Title>

        <Accordion>
          {faqs.map(({ question, answer }) => {
            return (
              <AccordionItem title={question} content={answer} key={question} />
            );
          })}
        </Accordion>
      </div>
    </Container>
  );
};

export default FAQ;
