import styled from "styled-components";

import BaseContainer from "../../components/BaseContainer";

export const Container = styled(BaseContainer)`
  background: #252525;
  color: white;
  flex-direction: column-reverse;
  padding: 2rem var(--gap);

  .image {
    @media screen and (max-width: 991px) {
      margin-bottom: 2rem;
    }
  }

  @media screen and (min-width: 992px) {
    padding: 4rem 0 4rem;
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  @media screen and (max-width: 995px) {
    padding: 0 0 var(--gap) var(--gap);
  }

  &.desktop-only {
    div,
    img {
      width: 100%;
      height: 50vh;
      max-height: 700px;
      object-position: center 45%;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;

  @media screen and (min-width: 992px) {
    padding: 2rem 80px;
  }
`;

export const SectionTitle = styled.h2`
  font-size: clamp(2rem, 3vw, 3rem);
  margin: 0 0 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
`;

export const SubTitle = styled.h3`
  margin: 0 0 0.5rem;
`;

export const Text = styled.div`
  line-height: 150%;
  margin-top: 0;

  em {
    color: #ff6a3c;
  }

  &:last-of-type {
    position: relative;
    margin-bottom: 3rem;
    &:after {
      content: "";
      display: block;
      position: absolute;
      text-align: right;
      height: 0.25rem;
      width: 200px;
      background-color: var(--color-05);
      bottom: -20px;
      right: 0px;
    }
  }
`;
