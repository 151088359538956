import styled from "styled-components";

export const Container = styled.div`
  --padding: 22px;
  text-align: center;
  background-color: #ff961c;
  color: white;
  font-size: 1.25rem;
  padding: var(--padding);
  position: relative;

  p {
    margin: 0;
    padding-right: 33px;

    b {
      svg {
        height: 1.2rem;
        width: 1.2rem;
        position: relative;
        top: 1px;
      }
    }
  }

  button {
    position: absolute;
    top: 18px;
    right: var(--padding);
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    padding: 0;
  }
`;
