import styled from "styled-components";

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--gap) var(--gap);
  background: #484848;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  & ~ footer {
    background: #434343;
  }
`;

export const Logo = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;

  svg {
    height: 60px;
  }

  @media screen and (min-width: 992px) {
    text-align: left;
    margin: 0;

    svg {
      height: initial;
    }
  }
`;

export const TextContainer = styled.div`
  h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;

    @media screen and (min-width: 992px) {
      text-align: left;
    }
  }
  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  p {
    font-size: clamp(18px, 2.6vw, 24px);
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0.5rem 0;

    svg {
      font-size: 46px;
      width: 3rem;
      margin-right: 1rem;
    }
  }
  margin-bottom: 1rem;

  @media screen and (min-width: 992px) {
    margin-bottom: 3rem;
    width: 50%;
    margin-bottom: 0;
  }
`;

export const AfterFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  p {
    text-align: center;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;
