import styled from "styled-components";

export const Container = styled.section`
  /* min-height: 100vh; */
  padding: 5rem var(--gap);

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
    max-width: var(--container);
    margin: 0 auto;
  }
`;

export const Title = styled.h2`
  font-size: clamp(2rem, 8.5vw, 3rem);
  text-transform: uppercase;
  text-align: center;
  color: #313131;
  margin: 0 auto;
`;
