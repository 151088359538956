import React from "react";
import { Container, Title, SubTitle, Collection } from "./styles";
import { StaticImage } from "gatsby-plugin-image";

const MoreModels = () => {
  return (
    <Container>
      <div>
        <Title>ALSO GET TO KNOW THE SAMURAI COLLECTION</Title>
        <SubTitle>
          LETHAL ELEGANCE AND COMFORT WITHOUT VISIBLE SCREW AND THREAD ON THE
          REAL SEAT. PATENT BY CHI.
        </SubTitle>

        <Collection>
          <StaticImage
            src="../../assets/images/rod-model-2.png"
            alt="Samurai Collection"
          />
          <StaticImage
            src="../../assets/images/rod-model-3.png"
            alt="Samurai Collection"
          />
        </Collection>
      </div>
    </Container>
  );
};

export default MoreModels;
