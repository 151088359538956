import * as React from "react";

import { useStaticQuery, graphql } from "gatsby";

import Theme from "../Theme";
import Seo from "../sections/Seo";
import TopHero from "../sections/TopHero";
import Testimonials from "../sections/Testimonials";
import DavinchiArt from "../sections/DavinchiArt";
import NewWay from "../sections/NewWay";
import NewRod from "../sections/NewRod";
import Experience from "../sections/Experience";
import MoreModels from "../sections/MoreModels";
import DavinChiRod from "../sections/DavinChiRod";
import Action from "../sections/Action";
import Specifications from "../sections/Specifications";
import Knife from "../sections/Knife";
import Patented from "../sections/Patented";
import Community from "../sections/Community";
import Footer from "../sections/Footer";
import FAQ from "../sections/FAQ";
// import Policies from "../sections/Policies";
import OrderNow from "../sections/OrderNow";
import OrderNowOld from "../sections/OrderNowOld";
import HurryMessage from "../sections/HurryMessage";
import { isFuture } from "../helpers/Date";

const saleEnds = new Date("May 19 2022 23:59:59 PDT");

// markup
const IndexPage = ({ data }) => {
  const product = data.products.nodes[0];
  const sale = {
    isSale: saleEnds && isFuture(saleEnds),
    saleEnds: saleEnds,
    price: product.price,
    salePrice: product.sale_price,
    sku: product.sku,
  };

  return (
    <>
      <Theme />
      <Seo />
      <main>
        <TopHero sale={sale} />
        <NewRod />
        <NewWay />
        <Knife />
        <DavinchiArt />
        <Experience />
        <Testimonials />
        {sale.isSale ? <OrderNow sale={sale} /> : <OrderNowOld />}
        <Community />
        <Specifications />
        <MoreModels />
        <FAQ />
      </main>
      <Footer />
    </>
  );
};

export const query = graphql`
  query HomePageQuery {
    products: allBigCommerceProducts(filter: { sku: { eq: "SKU-112-1" } }) {
      nodes {
        id
        sku
        name
        price
        sale_price
        is_visible
      }
    }
  }
`;

export default IndexPage;
