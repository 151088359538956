import styled from "styled-components";
import OrderNowComponent from "../../components/OrderNow";

export const Container = styled.section`
  width: 100%;
  /* min-height: 100vh; */
  margin: 0 auto;
  padding: 15px var(--gap) 15px var(--gap);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  position: relative;

  & > div {
    position: relative;
  }

  iframe {
    width: 100%;
    height: calc(100vw / 1.78);

    /* height: 18.9vw; */
  }

  &.desktop-only {
    padding: 20px 0 20px;
    width: 100vw;
    gap: 15px;
    justify-content: flex-start;

    .logo {
      margin-left: 5.83vw;
    }

    a {
      top: initial;
      right: initial;
      right: 5.9%;
      bottom: 6%;
      width: 20vw;
      height: 60px;
      line-height: 60px;
      padding: 0;
    }

    iframe {
      position: absolute;
      top: 50%;
      left: 5vw;
      width: 33.5vw;
      height: 18.9vw;
    }
  }

  @media screen and (min-width: 767px) {
    gap: 1.5rem;
  }

  @media screen and (min-width: 992px) {
    padding: 5.5rem;
  }

  @media screen and (min-width: 1280px) {
  }

  padding-bottom: 100px;

  .hurry-up {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
`;

export const TopMessage = styled.div`
  --padding: 22px;
  text-align: center;
  background-color: #ff961c;
  color: white;
  font-size: 1.25rem;
  padding: var(--padding);

  p {
    margin: 0;
    padding-right: 33px;

    b {
      svg {
        height: 1.2rem;
        width: 1.2rem;
        position: relative;
        top: 1px;
      }
    }
  }

  button {
    position: absolute;
    top: 18px;
    right: var(--padding);
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    padding: 0;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  line-height: 100%;
  max-width: 940px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  color: #393939;
  margin: 0;

  @media screen and (min-width: 768px) {
    font-size: clamp(65px, 8.4vw, 83px);
  }

  @media screen and (min-width: 992px) {
    width: 54%;
    position: absolute;
    top: 3rem;
    left: 3rem;
    font-size: clamp(49px, 4.6vw, 85px);
    line-height: 125%;
  }

  @media screen and (min-width: 1280px) {
  }
`;

export const Logo = styled.div`
  & > svg {
    height: 75px;
    @media screen and (min-width: 768px) {
      height: 70px;
      width: 215px;
    }
  }
`;

export const Text = styled.p`
  font-weight: 700;
  background: #ef691a;
  color: #fff;
  letter-spacing: 0.23em;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  display: none;

  @media screen and (min-width: 767px) {
    display: block;
  }

  @media screen and (min-width: 992px) {
    width: 40%;
    max-width: 500px;
    font-size: 20px;
    line-height: 150%;
    text-align: right;
    position: absolute;
    top: 3rem;
    right: 3rem;
    font-size: clamp(1.2rem, 1.92vw, 2rem);

    &:before {
      content: "";
      display: block;
      background: red;
      width: 20px;
    }
  }

  @media screen and (min-width: 1280px) {
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  height: 93px;

  @media screen and (min-width: 768px) {
    max-width: 574px;
    left: initial;
    margin: 0 auto;
    height: initial;
  }

  @media screen and (min-width: 992px) {
    position: absolute;
    top: 70%;
    left: 3rem;
  }

  @media screen and (min-width: 1280px) {
  }
`;

export const Price = styled.div`
  background: #ef691a;
  color: #313131;
  text-transform: uppercase;
  padding: 14px;
  font-size: 12px;
  flex: 1;

  &.secondary {
    background: #313131;
    color: #ef691a;
  }

  &.slashed {
    position: relative;
    &:after {
      content: "";
      border: 2px solid white;
      background: white;
      width: 90%;
      position: absolute;
      left: 5%;
      top: 50%;
      transform: rotate(-20deg);
    }
  }

  b {
    display: block;
    font-size: 54px;

    @media screen and (min-width: 768px) {
      font-size: 92px;
    }

    &::first-letter {
      font-size: 80%;
    }
  }

  @media screen and (min-width: 768px) {
  }

  @media screen and (min-width: 992px) {
    font-size: 11px;
    b {
      font-size: 80px;
    }
  }

  @media screen and (min-width: 1280px) {
  }
`;

export const ImageContainer = styled.section`
  width: 100vw;
  margin-left: calc(var(--gap) * -1);

  & ~ b {
    background: #ef691a;
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    padding: 11px;
    width: 100vw;
    margin-left: -1rem;

    @media screen and (min-width: 768px) {
      margin-left: -2rem;
    }
  }

  @media screen and (min-width: 1280px) {
  }
`;

export const OrderNow = styled(OrderNowComponent)`
  @media screen and (min-width: 768px) {
    width: 574px;
    margin: 0 auto;
  }

  @media screen and (min-width: 992px) {
    position: absolute;
    top: 70%;
    right: 3rem;
    font-size: 1.5rem;
    padding: 2rem 3rem;
    width: 30%;
    max-width: 500px;
  }
`;
