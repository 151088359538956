import styled from "styled-components";

export const Container = styled.section`
  padding: 5rem var(--gap);
  text-align: center;
  display: flex;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
    max-width: 1920px;
    margin: 0 auto;
  }
`;

export const Title = styled.h2`
  font-size: clamp(2rem, 8.5vw, 3rem);
  text-transform: uppercase;
  color: #313131;
  margin: 0 auto;
  text-align: center;
`;
