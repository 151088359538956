import styled from "styled-components";
import BaseContainer from "../../components/BaseContainer";

export const Container = styled(BaseContainer)`
  color: white;
  flex-direction: column;

  @media screen and (max-width: 991px) {
    padding: 2rem var(--gap);
  }

  @media screen and (min-width: 992px) {
    padding: 4rem 0 4rem;
    flex-direction: row;
    align-items: center;
  }

  .image {
    margin-bottom: 2rem;
    @media screen and (min-width: 992px) {
    }
  }
`;

export const IframeContainer = styled.div`
  iframe {
    width: 100%;
    max-width: 845px;
    aspect-ratio: 845/470;
  }
  @media screen and (min-width: 992px) {
    width: 50%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;

  @media screen and (min-width: 992px) {
    padding: 2rem 80px;
    width: 50vw;
  }
`;

export const SectionTitle = styled.h2`
  font-size: clamp(25px, 3vw, 3rem);
  margin: 0.75rem 0;
  text-transform: uppercase;
  font-weight: 700;
`;

export const SubTitle = styled.h3``;

export const Text = styled.p`
  line-height: 150%;
  margin-top: 0;

  em,
  a {
    color: #ff6a3c;
    font-style: normal;
  }

  &:last-of-type {
    &:after {
      content: "";
      display: block;
      position: relative;
      text-align: right;
      height: 0.25rem;
      width: 200px;
      background-color: var(--color-05);
      top: 0;
      right: 0px;
      margin-top: 20px;
    }
  }
`;
