import styled from "styled-components";

export const Container = styled.section`
  padding: 5rem var(--gap);
  background: #f8f8f8;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Title = styled.h2`
  color: #313131;
  font-size: clamp(2rem, 8.5vw, 3rem);
  margin: 0 auto 1rem;
  text-transform: uppercase;
  text-align: center;
`;

export const VideoEmbed = styled.div`
  max-width: 1220px;
  margin: 0 auto;

  & {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  &::after {
    display: block;
    content: "";
    padding-top: 56.25%;
  }

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:last-child {
    margin-top: 3rem;
  }
`;
