import styled from "styled-components";

export const Container = styled.section`
  padding: 5rem var(--gap);
  background: #f5f5f5;

  & > div {
    width: 100%;
    max-width: var(--container);
    margin: 0 auto;
  }

  .my-masonry-grid {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    gap: 1rem;
    display: flex;
    width: auto;
    justify-content: center;
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
  }
`;

export const Title = styled.h2`
  text-transform: uppercase;
  text-align: center;
  color: #313131;
  margin: 0 auto 2rem;
  font-size: clamp(2rem, 8.5vw, 3rem);
`;

export const Testimonial = styled.article`
  margin-bottom: 1rem;
  background: #fff;
  border-radius: 12px;

  img,
  iframe {
    width: 100%;
    margin-bottom: -4px;
  }
  p {
    margin: 0;
  }
  div {
    padding: 1rem;
  }
`;

export const Author = styled.p`
  color: #313131;
  font-size: 18px;
  font-weight: 500;
`;

export const Text = styled.p`
  color: #313131;
  font-style: italic;
`;

export const Rating = styled.p`
  color: #000;
  margin: 0;
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 0.5rem;
  margin-bottom: 0.5rem !important;
`;

export const VideosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;

  article {
    width: 100%;
    margin: 0;

    @media screen and (min-width: 992px) {
      width: calc((100% - 1rem) / 2);
    }

    @media screen and (min-width: 1366px) {
      width: calc((100% - 2rem) / 3);
    }

    .video-container {
      width: 100%;
      position: relative;
      padding: 0;
      /* aspect-ratio: 1.777; */

      &:after {
        content: "";
        display: block;
        padding-top: 53%;
      }
    }

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
`;
