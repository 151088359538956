import React from "react";
import { Container, Title, VideoEmbed } from "./styles";

const Experience = () => {
  return (
    <Container>
      <Title>The DavinChi Rod Experience</Title>

      <VideoEmbed>
        <iframe
          src="https://www.youtube.com/embed/bJqoD6aZm0M?rel=0"
          title="DavinChi Rod"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoEmbed>

      {/* <VideoEmbed>
        <iframe
          src="https://www.youtube.com/embed/bfpd3C4kMdg?rel=0"
          title="Captain Kurt Tillman used Davinchi Rod"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoEmbed> */}
    </Container>
  );
};

export default Experience;
