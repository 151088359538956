import styled from "styled-components";

export const Container = styled.section`
  padding: 5rem var(--gap);
  background: #252525;
  color: #f5f5f5;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    max-width: var(--container);
    margin: 0 auto;

    .block-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;

      @media screen and (min-width: 768px) {
        gap: 18px;
      }

      @media screen and (min-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 28px;
      }
    }
  }

  a {
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      width: 570px;
    }

    @media screen and (min-width: 992px) {
      padding-left: 10rem;
      padding-right: 10rem;
    }
  }
`;

export const Title = styled.h2`
  font-size: clamp(2rem, 8.5vw, 3rem);
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
`;

export const Block = styled.article`
  background: #313131;
  color: white;
  padding: 2rem 25px;
  text-align: center;

  @media screen and (min-width: 768px) {
    padding: 48px 40px;
  }

  @media screen and (min-width: 992px) {
    padding: 48px 10%;
  }

  svg {
    width: 100%;
    height: 100%;
    max-width: 200px;
    margin-bottom: 2rem;

    &:after {
      content: "";
      display: block;
      padding-top: 100%;
    }

    @media screen and (min-width: 768px) {
      margin-bottom: 60px;
    }
  }

  p {
    font-size: clamp(12px, 3.2vw, 18px);
    line-height: 150%;
    margin: 0;
  }
`;
