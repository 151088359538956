import React from "react";

import OrderNow from "../../components/OrderNow";
import { Container, Title, Block } from "./styles";

import ApprovedSvg from "../../assets/icons/v2/approved-by-professionals.svg";
import ErgonomicSvg from "../../assets/icons/v2/ergonomic-design.svg";
import CarbonSvg from "../../assets/icons/v2/carbon-fiber.svg";
import PatentedSvg from "../../assets/icons/v2/patented-exclusive.svg";

const DavinchiArt = () => {
  return (
    <Container>
      <div>
        <Title>The art of DavinChi</Title>
        <div className="block-wrapper">
          <Block>
            <div>
              <ApprovedSvg />
            </div>
            <p>
              <b>Engineered tested</b> for structural strength
            </p>
          </Block>
          <Block>
            <div>
              <PatentedSvg />
            </div>
            <p>
              <b>Exclusive patented</b> design
              <br />
              <small>Patent No.: US 7,854,086 B2</small>
            </p>
          </Block>
          <Block>
            <div>
              <ErgonomicSvg />
            </div>
            <p>
              <b>Ergonomic design</b> for comfort, strength and catching more
              fish
            </p>
          </Block>
          <Block>
            <div>
              <CarbonSvg />
            </div>
            <p>
              Rod and handle made with the finest <b>carbon fiber</b>
            </p>
          </Block>
        </div>
        <OrderNow />
      </div>
    </Container>
  );
};

export default DavinchiArt;
