import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import OrderNow from "../../components/OrderNow";

import { Container, TextContainer, SectionTitle, Text } from "./styles";

const Hero = () => {
  return (
    <section style={{ background: `#252525` }}>
      <Container>
        <TextContainer>
          <SectionTitle>A new Generation of fishing rod</SectionTitle>

          <Text style={{ marginBottom: "1rem" }}>
            The unnatural design of traditional fishing rods{" "}
            <em>forces tension and stress on your wrist</em>, hands, and
            tendons, which may lead to nerve compression syndrome, numbness, and
            pain in your joints, shoulder, and trapezius muscle.{" "}
          </Text>
          <Text>
            <b>
              Constant use of traditional fishing rods may cause chronic injury
            </b>
            <br /> The following image demonstrates the intense amount of stress
            to the wrist from holding a straight fishing rod.
          </Text>
          <OrderNow />
        </TextContainer>

        <StaticImage
          src="../../assets/images/v2/traditional-rod.png"
          alt="Capitan Marcus holding the Cobra DavinChi Rod"
          quality="100"
          // style={{ height: "100%" }}
          className="image"
        />
      </Container>
    </section>
  );
};

export default Hero;
