import React from "react";
import OrderNow from "../../components/OrderNow";

import {
  Container,
  IframeContainer,
  TextContainer,
  SectionTitle,
  Text,
} from "./styles";

const NewWay = () => {
  return (
    <section style={{ background: `#2b2b2b`, marginBottom: "-1px" }}>
      <Container>
        <IframeContainer>
          <iframe
            // width="845"
            // height="470"
            src="https://www.youtube.com/embed/nbiWkMFfHWI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </IframeContainer>
        <TextContainer>
          <SectionTitle>The science of DavinChi Rod</SectionTitle>
          <Text>
            The DavinChi Fishing Rod has a perfect, natural, and comfortable
            grip,{" "}
            <em>
              increasing your power and reducing six times the weight
              traditional rods place on the wrist
            </em>
            . This means greater control with more power, more comfort, and more
            fish.
          </Text>
          <Text>
            The DavinChi Rod is designed by Chi Huynh, an award-winning{" "}
            <a
              target="_blank"
              href="https://galateausa.com"
              rel="noopener noreferrer"
            >
              designer and artist
            </a>
            . The DavinChi Rod is perfect for everyday fishing at the shore,
            pier, lake, or on a boat.
          </Text>
          <Text>
            The tip is sensitive enough to catch a 1-pound fish and powerful
            enough to handle a 25 pounds fish. Though the included tip is not
            designed for 50+ pound fish, we encourage you to test the rod's
            limits. If it breaks, we will replace it at no charge.
          </Text>
          <OrderNow />
        </TextContainer>
      </Container>
    </section>
  );
};

export default NewWay;
