import React from "react";
import styled from "styled-components";

const Container = styled.section`
  padding: 5rem var(--gap);
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 992px) {
    padding: 0 var(--gap);
    flex-direction: row;
    max-width: var(--container);
    margin: 0 auto;

    & > div {
      width: 50%;
    }
  }
`;

const BaseContainer = (props) => {
  return <Container {...props} />;
};

export default BaseContainer;
