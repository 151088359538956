import React, { useState } from "react";

import { Container, Item, Header, Title, Content } from "./style";

import ExpandIcon from "../../assets/icons/v2/expand.svg";
import CollapseIcon from "../../assets/icons/v2/collapse.svg";

const Accordion = ({ children }) => {
  return <Container>{children}</Container>;
};

export const AccordionItem = ({ title, content, color }) => {
  const [expanded, setExpanded] = useState(false);

  function handleClick() {
    setExpanded(!expanded);
  }

  return (
    <Item color={color}>
      <Header onClick={handleClick} expanded={expanded}>
        <Title>{title}</Title>
        {expanded ? <CollapseIcon /> : <ExpandIcon />}
      </Header>
      <Content
        expanded={expanded}
        dangerouslySetInnerHTML={{ __html: content }}
      ></Content>
    </Item>
  );
};

export default Accordion;
