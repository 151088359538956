import styled from "styled-components";

export const Container = styled.a`
  color: white;
  padding: 1rem 2rem;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  background-color: #ef691a;
  transition: background-color 200ms;

  &:not(:disabled):hover {
    background-color: #a64b15;
  }

  &:not(:disabled):active {
    background-color: #823b12;
  }
`;
