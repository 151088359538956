import React from "react";
import { Container } from "./styles";
import scrollTo from "gatsby-plugin-smoothscroll";

const OrderNow = ({ ...props }) => {
  return (
    <Container
      href="#order-now"
      {...props}
      onClick={(e) => {
        e.preventDefault();
        scrollTo("#order-now");
      }}
    >
      Order now
    </Container>
  );
};

export default OrderNow;
