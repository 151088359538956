import React from "react";

import {
  Container,
  Title,
  Testimonial,
  Author,
  Text,
  VideosContainer,
} from "./styles";

const Community = () => {
  const videos = [
    {
      id: `v1233`,
      video: "https://www.youtube.com/embed/w29591jUkA4?rel=0",
      author: "American Outdoors interviewing DavinChi Rod",
    },
    {
      id: `v1233`,
      video: "https://www.youtube.com/embed/bfpd3C4kMdg?rel=0",
      author: "Kurt Tillman bought the DavinChi Rod",
      text: `Fishing in this video was done with captain <a href="https://app.fishingchaos.com/charter/captain-kurt-charters" rel="nofollow">Kurt Tillman</a>.`,
    },
    {
      id: `v1234`,
      video: "https://www.youtube.com/embed/sr57rlW15TQ?rel=0",
      author: "Eric Testing and Catching Big Yellow Tail with DavinChi Rod",
    },
    {
      id: `v1233`,
      video: "https://www.youtube.com/embed/L7lX0z0VFwU?rel=0",
      author: "Captain Charlie bought the DavinChi Rod",
    },
  ];

  return (
    <Container>
      <div>
        <Title>DavinChi Rod Community</Title>

        <VideosContainer>
          {videos.map(({ id, video, author, text }) => (
            <Testimonial key={id}>
              <div className="video-container">
                {video && (
                  <iframe
                    width="560"
                    height="200"
                    src={video}
                    title="DavinChi Rod"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
              <div>
                {author && <Author>{author}</Author>}
                {text && (
                  <Text dangerouslySetInnerHTML={{ __html: text }}></Text>
                )}
              </div>
            </Testimonial>
          ))}
        </VideosContainer>

        {/* <Masonry
          breakpointCols={{
            1400: 5,
            1200: 4,
            992: 3,
            768: 2,
            default: 6,
          }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {testimonials.map(({ id, img, author, text }, index) => (
            <Testimonial key={id + "" + index}>
              {img && (
                <img src={img} alt={`${author} said about DavinChi Rod`} />
              )}
              <div>
                <Author>{author}</Author>
                <Text>“{text}”</Text>
              </div>
            </Testimonial>
          ))}
        </Masonry> */}
      </div>
    </Container>
  );
};

export default Community;
