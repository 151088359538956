import styled, { css } from "styled-components";

const expandIcon = "../../assets/icons/v2/icon-expand.svg";
const collapseIcon = "../../assets/icons/v2/icon-collapse.svg";

export const Container = styled.ul`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  width: 100%;
`;

export const Item = styled.li`
  list-style: none;
  overflow: visible;
  transition: all 0.11s cubic-bezier(0.2, 0, 0.38, 0.9);
  overflow: hidden;
  border: 1px solid #3d3d3d;

  ${({ color }) =>
    color &&
    css`
      border-color: ${color};
      svg path {
        fill: ${color};
      }
    `}

  & ~ li {
    border-top: 0;
  }
`;

export const Header = styled.button`
  vertical-align: baseline;
  display: inline-block;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 3.8rem;
  margin: 0;
  padding: 1rem 1rem 1rem 2rem;
  cursor: pointer;
  background-color: white;
  border: 0;

  &:after {
    /* content: url(${(props) =>
      props.expanded ? collapseIcon : expandIcon}); */
    position: absolute;
    top: calc(50% - 12px);
  }
`;

export const Title = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375;
  letter-spacing: 0;
  padding-right: 2rem;
  text-align: left;
  font-size: 1.15rem;
  line-height: 2.2rem;
  color: #313131;
`;

export const Content = styled.div`
  display: ${(props) => (props.expanded ? "block" : "none")};
  padding: 1.2rem 1.8rem;
  background: #f0f0f0;
  font-size: 1.15rem;
  line-height: 1.6rem;
  color: #313131;
  border-top: 1px solid;

  ul,
  ol {
    &,
    & * {
      color: inherit;
    }
  }

  p {
    &,
    & > * {
      color: inherit;
    }
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    padding: 1.5rem 2.5rem;
  }
`;
