export const getDateDiff = (startTime, endTime) => {
  const diff = new Date(endTime.getTime() - startTime.getTime());
  return {
    year: diff.getUTCFullYear() - 1970,
    month: diff.getUTCMonth(),
    day: diff.getUTCDate() - 1,
    hour: diff.getUTCHours(),
    minute: diff.getUTCMinutes(),
    second: diff.getUTCSeconds(),
  };
};

export const diffInDays = function (from, to) {
  const dt1 = new Date(to);
  const dt2 = new Date(from);

  return Math.floor((dt1 - dt2) / (1000 * 3600 * 24));
};

export const formatDate = (date) => {
  let d = new Date(date),
    month = (d.getMonth() + 1).toString(),
    day = d.getDate().toString(),
    year = d.getFullYear().toString();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const isFuture = (date) => {
  return date > new Date();
};
