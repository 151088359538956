import { createGlobalStyle } from "styled-components";
import "normalize.css";

const Theme = createGlobalStyle`
  
  :root {
    /* COLORS */
    --color-01: #1F1F1F;
    --color-02: #313131;
    --color-03: #727272;
    --color-04: #ffffff;
    --color-05: #D25D18;
    --color-06: #FF6A3C;

    /* SIZES */
    --size-small: 576px;
    --size-medium: 768px;
    --size-large: 992px;
    --size-xlarge: 1200px;

    /* BTN PRIMARY STATES */
    --btn-default-bg: #d25d18;
    --btn-hover-bg: #a64b15;
    --btn-active-bg: #823b12;
    --btn-disabled-bg: #813b11;

    --gap: 1rem;
    @media screen and (min-width: 768px) {
      --gap: 2rem;
    }

    @media screen and (min-width: 992px) {
      --gap: 3rem;
      --container: 1690px;
    }
  }

  html, body {
    color: var(--color-04);
    font-size: 16px;
    overflow-x: hidden;
    position: relative;
  }

  * {
    font-family: Montserrat, Arial, sans-serif;
    box-sizing: border-box;
  }

  a {
    cursor: pointer;
  }

  header#header {
  }

  main {}

  .mobile-only {
    @media screen and (min-width: 992px) {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .desktop-only {
    @media screen and (max-width: 991px) {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }
`;

export default Theme;
