import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  /* min-height: 100vh; */
  padding: 5rem var(--gap);
  align-items: center;
  color: #313131;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;

  div {
    display: flex;
    max-width: var(--container);
    margin: 0 auto;
    gap: 3rem;
    flex-direction: column;
    @media screen and (min-width: 992px) {
      flex-direction: row;
    }
  }

  article {
    flex: 1;
    img,
    video {
      width: 100%;
    }

    p {
      &:after {
        content: "";
        display: block;
        position: relative;
        height: 0.25rem;
        width: 200px;
        background-color: var(--color-05);
        top: 0;
        left: 0;
        margin-top: 20px;
      }
    }
  }
`;

export const Title = styled.h2`
  font-size: clamp(2rem, 8.5vw, 3rem);
  text-transform: uppercase;
  color: #313131;
  margin: 0 auto;
  text-align: center;
`;

export const Testimonial = styled.article`
  h3 {
  }
`;
