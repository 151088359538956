import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import {
  Container,
  Row,
  TextContainer,
  SectionTitle,
  SectionSubTitle,
  ImageContainer,
  Text,
} from "./styles";

const Knife = () => {
  return (
    <section style={{ background: "#313131" }}>
      <Container>
        <Row>
          <TextContainer className="right">
            <SectionTitle>
              Ever forgotten your knife on a fishing trip?
            </SectionTitle>
            <SectionSubTitle>
              Not to worry, we’ve got you covered!
            </SectionSubTitle>
          </TextContainer>
          <ImageContainer>
            <StaticImage
              src="../../assets/images/v2/knife.jpg"
              alt="DavinChi Rod knife built in damascus steel"
            />
          </ImageContainer>
        </Row>

        <Row className="second">
          <ImageContainer>
            <StaticImage
              src="../../assets/images/v2/knife-2.jpg"
              alt="DavinChi Rod's knife up close"
            />
          </ImageContainer>
          <TextContainer>
            <Text>
              Each DavinChi Rod comes with a hidden knife in the bottom of the
              rod’s handle. It will always be there when you need it the most. •
              High-quality Damascus steel • Strong and durable wood handle •
              Designed for peace of mind.
            </Text>
          </TextContainer>
        </Row>
      </Container>
    </section>
  );
};

export default Knife;
