import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import { Container, Title } from "./styles";

const Specifications = () => {
  return (
    <Container>
      <div>
        <Title>Specifications</Title>
        <StaticImage
          src="../../assets/images/v2/specifications.png"
          alt="DavinChi Rod Specifications"
          className="desktop-only"
        />
        <StaticImage
          className="mobile-only"
          src="../../assets/images/v2/specifications-mobile.png"
          alt="DavinChi Rod Specifications"
        />
      </div>
    </Container>
  );
};

export default Specifications;
