import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { useLocation } from "@reach/router";
import { diffInDays, getDateDiff } from "../../helpers/Date";

import {
  Container,
  Gallery,
  Dialog,
  Info,
  Title,
  Description,
  Form,
  Price,
  Button,
  Urgency,
  Countdown,
} from "./styles";

import ClockIcon from "../../assets/icons/clock.svg";

import img1 from "../../assets/gallery/1.png";
import img2 from "../../assets/gallery/2.png";
import img3 from "../../assets/gallery/3.png";
import img4 from "../../assets/gallery/4.png";
import img5 from "../../assets/gallery/5.png";
import img6 from "../../assets/gallery/6.png";
import img7 from "../../assets/gallery/7.png";
import img8 from "../../assets/gallery/8.png";
import img9 from "../../assets/gallery/9.png";

import thumb1 from "../../assets/gallery/mini-1.png";
import thumb2 from "../../assets/gallery/mini-2.png";
import thumb3 from "../../assets/gallery/mini-3.png";
import thumb4 from "../../assets/gallery/mini-4.png";
import thumb5 from "../../assets/gallery/mini-5.png";
import thumb6 from "../../assets/gallery/mini-6.png";
import thumb7 from "../../assets/gallery/mini-7.png";
import thumb8 from "../../assets/gallery/mini-8.png";
import thumb9 from "../../assets/gallery/mini-9.png";
import Checkbox from "../../components/Checkbox";
import { StaticImage } from "gatsby-plugin-image";

const OrderNow = ({ sale }) => {
  const [price, setPrice] = useState("SKU-112-1");
  const [checkoutUrl, setCheckoutUrl] = useState("");
  const [diff, setDiff] = useState({});
  const [pricing, setPricing] = useState({
    markdown: 0,
    market: 0,
  });

  useEffect(() => {
    if (sale) {
      setPricing({
        markdown: sale.salePrice,
        market: sale.price,
      });

      // if (sale.sku) {
      //   setPrice(sale.sku);
      // }
      if (sale.saleEnds) {
        const timer = setInterval(() => {
          setDiff(getDateDiff(new Date(), sale.saleEnds));
        }, 1000);
        return () => clearInterval(timer);
      }
    }
  }, [sale]);

  useEffect(() => {}, []);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.append("action", "buy");

    setCheckoutUrl(
      "https://shop.davinchirod.com/cart.php?" + searchParams.toString()
    );
  }, [location]);

  const images = [
    {
      original: img1,
      thumbnail: thumb1,
      originalAlt: "Close up view of the Cobra DavinChi Rod unique handle",
      thumbnailAlt: "Go to slide 1",
    },
    {
      original: img2,
      thumbnail: thumb2,
      originalAlt: "Cobra DavinChi Rod side view angle",
      thumbnailAlt: "Go to slide 2",
    },
    {
      original: img3,
      thumbnail: thumb3,
      originalAlt: "Cobra DavinChi Rod perspective overview",
      thumbnailAlt: "Go to slide 3",
    },
    {
      original: img4,
      thumbnail: thumb4,
      originalAlt: "Cobra DavinChi Rod perspective close up",
      thumbnailAlt: "Go to slide 4",
    },
    {
      original: img5,
      thumbnail: thumb5,
      originalAlt: "",
      thumbnailAlt: "Go to slide 5",
    },
    {
      original: img6,
      thumbnail: thumb6,
      originalAlt: "DavinChi Rod cover",
      thumbnailAlt: "Go to slide 6",
    },
    {
      original: img7,
      thumbnail: thumb7,
      originalAlt: "DavinChi Rod top view",
      thumbnailAlt: "Go to slide 7",
    },
    {
      original: img8,
      thumbnail: thumb8,
      originalAlt: "DavinChi Rod companion knife",
      thumbnailAlt: "Go to slide 8",
    },
    {
      original: img9,
      thumbnail: thumb9,
      originalAlt: "DavinChi Rod line guide",
      thumbnailAlt: "Go to slide 9",
    },
  ];

  const renderUrgency = () => {
    const diff = diffInDays(new Date(), sale.saleEnds);

    return (
      <Urgency>
        <ClockIcon />
        {diff === 0 ? (
          <b>Ends today!</b>
        ) : (
          <>
            <b>
              {diff} {diff > 1 ? "days" : "day"}
            </b>{" "}
            Left at this price!
          </>
        )}
      </Urgency>
    );
  };

  const renderCountDown = () => {
    const { year, month, day, hour, minute, second } = diff;
    const hours = hour + day * 24 + month * 30 * 24 + year * 365 * 24;

    return (
      <Countdown>
        <div>
          {hours ? new String(hours).padStart(2, "0") + ":" : "00:"}
          {minute ? new String(minute).padStart(2, "0") + ":" : "00:"}
          {second ? new String(second).padStart(2, "0") + "" : "00"}
        </div>
        <p>
          This promotion is <em>Limited time</em>. Collect yours today.
        </p>
      </Countdown>
    );
  };

  const renderMarkdownPrice = () => {
    return (
      <Form className="slashed-price">
        <div>
          <Price className="price">
            <b>
              ${pricing.markdown}
              {/* <sub>.00</sub> */}
            </b>

            <b className="slashed">
              <span>
                ${pricing.market}
                {/* <sup>.00</sup> */}
              </span>
            </b>
          </Price>
        </div>

        {renderUrgency()}
        {renderCountDown()}
        <Button as="a" href={checkoutUrl + "&sku=" + price}>
          Buy it now
        </Button>
        <StaticImage
          src="../../assets/images/v2/cards.png"
          alt="Accepted credit cards"
          quality="100"
          style={{ height: "100%", width: "auto" }}
        />
      </Form>
    );
  };

  const renderSinglePrice = () => {
    return (
      <Form className="single-price">
        <Price className="price">
          <div>
            <b>
              ${pricing.markdown}
              <sup>.00</sup>
            </b>
          </div>
        </Price>
        <Button as="a" href={checkoutUrl + "&sku=" + price}>
          Order now
        </Button>
        <StaticImage
          src="../../assets/images/v2/cards.png"
          alt="Accepted credit cards"
          quality="100"
          style={{ height: "100%", width: "auto" }}
        />
      </Form>
    );
  };

  return (
    <Container id="order-now">
      <div>
        <Gallery>
          <ImageGallery items={images} />
        </Gallery>
        <Info>
          <Title>Cobra DavinChi Rod</Title>
          <Description>
            Carbon fiber Cobra DavinChi Rod with detachable tip
            <br /> Total Length: 8.5 feet Handle
            <br /> length: 30 inches
            <br /> Tip length: 72 inches
            <br /> Tip weight: 08- 12 lbs medium action
            <br /> Arm support
            <br /> Guide: alconite ring and stainless steel frame
            <br /> Knife: Damascus steel with wood handle
          </Description>

          {sale.isSale ? renderMarkdownPrice() : renderSinglePrice()}
        </Info>
      </div>
    </Container>
  );
};

export default OrderNow;
