import React from "react";
import Masonry from "react-masonry-css";

import {
  Container,
  Title,
  Testimonial,
  Author,
  Text,
  VideosContainer,
} from "./styles";

import carlos from "../../assets/community/carlos-reynosa.jpeg";
import conrad from "../../assets/community/conrad.png";
import george from "../../assets/community/george.png";
import chi from "../../assets/community/chi.png";
import eric from "../../assets/community/eric.png";
import mike from "../../assets/community/mike.png";
import pablo from "../../assets/community/pablo.jpeg";
import doug from "../../assets/community/doug.jpg";

const Testimonials = () => {
  const testimonials = [
    {
      id: 1235,
      img: doug,
      author: "Doug",
      text: "Perfect at the pier. I can rest the snake curve over the rail too. I like it.",
    },
    {
      id: 1231,
      img: carlos,
      author: "Carlos Reynosa",
      text: "Love my rod.",
    },
    {
      id: 1232,
      img: pablo,
      author: "Pablo",
      text: "This rod has a good backbone for big and small fish without carrying two rods on my fishing trip.",
    },
    {
      id: 1238,
      img: george,
      author: "George",
      text: "I've been fishing for years and have dozens of rods, but this is the most comfortable rod I have ever owned.",
    },
    {
      id: 1234,
      img: conrad,
      author: "Conrad",
      text: "It is very comfortable and more powerful for sure.",
    },
    {
      id: "mike",
      img: mike,
      author: "Mike",
      text: "Classy rod for classy people.",
    },
    {
      id: 1233,
      img: eric,
      author: "Eric",
      text: "This is bad ass",
    },
    {
      id: 1235,
      img: chi,
      author: "Chi",
      text: "Fits perfect on my boat.",
    },
  ];

  const videos = [
    {
      id: `v1233`,
      video: "https://www.youtube.com/embed/L7lX0z0VFwU?rel=0",
      author: "Captain Charlie",
    },
    {
      id: `v1234`,
      video: "https://www.youtube.com/embed/Y1roH3Rg7lw?rel=0",
      author: "Jess Nelson",
    },
  ];

  return (
    <Container>
      <div>
        <Title>Testimonials</Title>

        {/* <VideosContainer>
          {videos.map(({ id, video, author, text }) => (
            <Testimonial key={id}>
              <div className="video-container">
                {video && (
                  <iframe
                    width="560"
                    height="200"
                    src={video}
                    title="DavinChi Rod"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
              <div>
                {author && <Author>{author}</Author>}
                {text && <Text>“{text}”</Text>}
              </div>
            </Testimonial>
          ))}
        </VideosContainer> */}

        <Masonry
          breakpointCols={{
            1400: 5,
            1200: 4,
            992: 3,
            768: 2,
            default: 6,
          }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {testimonials.map(({ id, img, author, text }, index) => (
            <Testimonial key={id + "" + index}>
              {img && (
                <img src={img} alt={`${author} said about DavinChi Rod`} />
              )}
              <div>
                <Author>{author}</Author>
                <Text>“{text}”</Text>
              </div>
            </Testimonial>
          ))}
        </Masonry>
      </div>
    </Container>
  );
};

export default Testimonials;
