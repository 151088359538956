import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import {
  Container,
  Logo,
  ImageContainer,
  PriceContainer,
  Title,
  Price,
  OrderNow,
} from "./styles";

import LogoSvg from "../../assets/icons/v2/logo-header.svg";
import HurryMessage from "../HurryMessage";

const TopHero = ({ sale }) => {
  const price = sale.price;
  const salePrice = sale.salePrice;

  return (
    <>
      <Container className="desktop-only">
        <Logo className="logo">
          <LogoSvg />
        </Logo>

        <div>
          <StaticImage
            src="../../assets/images/v2/hero-rod.png"
            alt="Cobra DavinChi Rod"
            layout="fullWidth"
            placeholder="dominantColor"
            quality={100}
          />

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/8c8FOGfMz28?autoplay=1&mute=1&loop=1&rel=0"
            title="DavinChi Rod / The new Generation of Fishing Rod."
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          <OrderNow />
        </div>

        {/* {sale.isSale && <HurryMessage sale={sale} />} */}
      </Container>

      <Container className="mobile-only">
        <Logo>
          <LogoSvg />
        </Logo>

        <Title>6x more leverage power than traditional fishing rods</Title>
        <ImageContainer>
          <StaticImage
            src="../../assets/images/v2/tophero-mobile.png"
            alt="Cobra DavinChi Rod"
            placeholder="dominantColor"
            // layout="fullWidth"
            quality="100%"
          />
        </ImageContainer>
        <b>MORE POWER * MORE COMFORT * MORE FISH</b>

        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/8c8FOGfMz28?autoplay=1&loop=1&rel=0"
          title="DavinChi Rod / The new Generation of Fishing Rod."
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>

        <OrderNow />

        {/* {sale.isSale && <HurryMessage sale={sale} />} */}
      </Container>
    </>
  );
};

export default TopHero;
