import styled from "styled-components";

export const Container = styled.section`
  background: #252525;
  padding: 5rem var(--gap);

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: var(--container);
    margin: 0 auto;
  }
`;

export const Title = styled.h2`
  color: #ffffff;
  font-size: clamp(1.56rem, 6.6vw, 3rem);
  font-weight: 700;
  margin: 0 auto 1rem;
  text-transform: uppercase;
  text-align: center;
`;

export const SubTitle = styled.h3`
  font-size: clamp(1rem, 4.2vw, 2rem);
  line-height: 150%;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto 1rem;
  text-align: center;
`;

export const Collection = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;

  & > * {
    flex: 1;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
