import styled from "styled-components";

export const PatentNo = styled.div`
  width: 80%;
  max-width: 400px;
  position: absolute;
  background: #313131;
  border: 1px solid #b5b5b5;
  color: white;
  font-size: 18px;
  font-weight: 500;
  min-height: 3rem;
  line-height: 3rem;
  text-align: center;
  cursor: default;

  @media screen and (max-width: 995px) {
    bottom: var(--gap);
    left: 50%;
    transform: translate(-50%, 0);
  }

  @media screen and (min-width: 995px) {
    bottom: var(--gap);
    right: var(--gap);
  }
`;
