import styled from "styled-components";
import "react-image-gallery/styles/css/image-gallery.css";
import souldOutSrc from "../../assets/images/soldout.png";

export const Container = styled.section`
  min-height: 100vh;
  width: 100vw;
  padding: 5rem var(--gap);
  background: #313131;
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
    max-width: var(--container);

    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (min-width: 1200px) {
      flex-direction: row;
    }
  }

  &.soldout .image-gallery-slide {
    &::before {
      position: absolute;
      content: "";
      width: 200px;
      height: 119px;
      background-image: url(${souldOutSrc});
      opacity: 0.5;
    }
  }
`;

export const Dialog = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  padding: 20px;
  width: calc(100vw - var(--gap) - var(--gap));
  max-width: 650px;
  background: #f4f4f4;
  color: #313131;
  text-transform: initial;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  transform: translate(0px, -100%);

  p {
    line-height: 150%;
  }

  @media screen and (min-width: 992px) {
    top: initial;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -25px);
    width: 50vw;
  }

  & > button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #ef691a !important;
    border: 0 !important;
    font-size: 2rem !important;
  }

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top: 20px solid #f4f4f4;
    position: absolute;
    top: 99%;
    right: 8px;
    /* transform: translateX(-50%); */

    @media screen and (min-width: 992px) {
      top: 99%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const Logo = styled.div`
  svg {
    width: 227px;
    height: 75px;
  }
  @media screen and (min-width: 992px) {
    svg {
      width: 175px;
      height: 80px;
    }
  }
`;

export const Gallery = styled.div`
  flex: 1;
  @media screen and (min-width: 1200px) {
    width: 50%;
  }

  .image-gallery {
    &-thumbnails {
      padding: 0;
    }

    &-thumbnails-container {
      /* 
      display: flex;
      flex-direction: row;
      gap: 4px;
      */
      text-align: initial;
      margin-top: 4px;
    }

    .image-gallery-thumbnails-wrapper {
      /* max-width: 945px;
      overflow-x: scroll; */
    }

    &-thumbnail {
      border: none;
    }

    @media screen and (min-width: 1200px) {
      height: 100%;

      .image-gallery-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .image-gallery-slide-wrapper {
          flex: 1;

          .image-gallery-swipe {
            height: 100%;

            .image-gallery-slides {
              height: 100%;

              .image-gallery-slide {
                height: 100%;

                img {
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }

        .image-gallery-icon:hover {
          color: #ef691a;
        }
      }
    }
  }
`;

export const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 1200px) {
    max-width: 650px;
  }
`;

export const Title = styled.h2`
  color: white;
  font-size: 32px;
  margin: 0;
  border-bottom: 1px solid #ddd;
`;

export const Description = styled.p`
  color: white;
  font-size: 18px;
  line-height: 24px;
`;

export const Form = styled.form`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
`;

export const Price = styled.label`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  --status-color: ${(props) => (props.active ? "#EF691A" : "white")};
  /* border: 1px solid var(--status-color); */
  width: 100%;
  position: relative;

  .button-container {
    @media screen and (min-width: 992px) {
      position: relative;
    }

    button {
      background: transparent;
      color: white;
      border: 1px solid white;
      border-radius: 50px;
      display: inline-block;
      padding: 0;
      width: 25px;
      line-height: 23px;
      height: 25px;
      text-align: center;
      font-size: 16px;
      cursor: pointer;

      position: absolute;
      right: 1rem;
      top: 1rem;

      @media screen and (min-width: 992px) {
        &:not(.close-dialog) {
          position: initial;
        }
      }

      &:hover,
      &:active {
        color: #ef691a;
        border: 1px solid #ef691a;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    width: initial;
    flex-direction: row;
    min-width: 50%;
    /* padding: 1rem 0 1rem 1rem; */
    padding: 0;
  }

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1rem 0 1rem;
    width: 100%;

    & > div {
      label,
      input,
      span {
        width: 1.2rem;
        height: 1.2rem;
        margin: 0;
      }
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
    }

    @media screen and (min-width: 1200px) {
      width: 50%;
      flex-wrap: wrap;
      padding: 0 1rem 0 0;
    }

    & ~ div {
      border-top: 1px solid white;
      align-items: center;
      justify-content: center;
      padding: 1rem 0 1rem;

      @media screen and (min-width: 1200px) {
        border: none;
        border-left: 1px solid white;
        padding: 0 1rem 0;
      }
    }
  }

  b {
    display: inline-block;
    font-size: 66px;
    color: var(--status-color);
    sup {
      font-size: 60%;
    }
  }
`;

export const SoldOut = styled.p`
  border: 1px solid white;
  text-transform: uppercase;
  padding: 1rem;
  font-size: 1.2rem;
  width: 100%;
  text-align: center;

  b {
    color: red;
    font-size: 1.2rem;
  }
`;

export const Button = styled.button`
  color: white;
  padding: 1rem 2rem;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  background-color: #ef691a;
  transition: background-color 200ms;
  border: none;
  cursor: pointer;
  width: 100%;

  &:not(:disabled):hover {
    background-color: #a64b15;
  }

  &:not(:disabled):active {
    background-color: #823b12;
  }
`;
