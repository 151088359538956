import styled from "styled-components";

export const Container = styled.section`
  padding: var(--gap);
  background: var(--color-02);

  @media screen and (min-width: 992px) {
    padding: var(--gap) 0;
    max-width: var(--container);
    margin: 0 auto;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  &.second {
    flex-direction: column-reverse;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row !important;
    height: 50vh;
    max-width: 1920px;
    margin: 0 auto;

    & > * {
      width: 50%;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;

  @media screen and (min-width: 992px) {
    width: 50%;
    padding: 2rem 80px;

    text-align: left;
    &.right {
      text-align: right;
    }
  }
`;

export const SectionTitle = styled.h2`
  margin: 0 0 1rem 0;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;

  @media screen and (min-width: 768px) {
    font-size: 3rem;
  }

  @media screen and (min-width: 992px) {
    margin: 0 0 1rem 0;
    font-size: clamp(2rem, 2.7vw, 3rem);
  }
`;

export const SectionSubTitle = styled.h3`
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
  margin: 0 0 1rem 0;

  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }

  @media screen and (min-width: 992px) {
    font-size: clamp(1.5rem, 2vw, 2rem);
  }
`;

export const ImageContainer = styled.div`
  &,
  div,
  img {
    /* width: 100%; */
    height: 100%;
    /* margin-left: var(--gap); */
  }
  div,
  img {
    width: 100%;
  }

  &:nth-of-type(2n + 1) {
    text-align: right;
  }
  @media screen and (max-width: 991px) {
    width: calc(100vw - var(--gap));

    &:nth-of-type(2n + 1) {
      position: relative;
      margin-left: -2rem;
    }
  }
`;

export const Text = styled.p`
  font-weight: 500;
  line-height: 200%;
  position: relative;
  margin-bottom: 30px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    text-align: right;
    height: 0.25rem;
    width: 200px;
    background-color: var(--color-05);
    bottom: -20px;
    left: 0px;
  }

  @media screen and (max-width: 991px) {
    margin: 2rem 0 3rem;

    &:last-of-type {
      margin-bottom: 5rem;
      text-align: left;
      &:after {
        left: 0px;
        right: initial;
      }
    }
  }
`;
